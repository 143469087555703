<template>
    <b-modal
      id="modal-export-cabang"
      hide-footer
      ok-only
      ok-title="Accept"
      centered
      size="md"
      title="Unduh Cabang"
      class="custom__modal"
      no-close-on-backdrop
    >
      <div>
        <b-row class="p-2">
          <b-col cols="12" class="mb-2 custom__form--input">
            <b-form-group>
              <label for="v-merchant">Cabang</label>
              <v-select
                id="v-merchant"
                v-model="formPayload.merchant_uuid"
                placeholder="Pilih Cabang"
                label="title"
                :options="options"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div class="button__group">
        <b-button
          id="button--cancel__transaction"
          class="button__cancel"
          @click="$bvModal.hide('modal-export-cabang')"
        >
          Batal
        </b-button>
        <b-button 
          id="button--save__transaction" 
          class="button__save" 
          @click="exportReport(formPayload)">
          <!-- <b-spinner
          v-if="isLoadingExport"
          variant="primary"
          label="Loading..."
          /> -->
          Simpan
        </b-button>
      </div>
    </b-modal>
  </template>
  
  <script>
  import _ from "lodash";
  import vSelect from "vue-select";
  import {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BSpinner,
    BFormSelect,
  } from "bootstrap-vue";
  
  export default {
    data() {
      return {
        formPayload: {
          merchant_uuid: '',
        },
      };
    },
    components: {
      BFormGroup,
      BButton,
      BSpinner,
      vSelect,
      BRow,
      BCol,
      BFormSelect,
    },
    props: {
      getData: {
        type: Function,
      },
      result: {
        type: Object,
      },
      options: {
        type: Array,
      },
      getDetail: {
        type: Function
      },
      exportReport: {
        type: Function
      }
    },
    created() {

    },
  };
  </script>
  
  <style lang="scss">
  @import "@/assets/scss/variables/_variables.scss";
  @import '@core/scss/vue/libs/vue-select.scss';
  
  .modal-content {
    border-radius: 16px;
  
    .modal-header {
      background-color: #fff !important;
      border-bottom: 1px solid #eee !important;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
  
      .modal-title {
        margin-left: auto;
      }
    }
  
    .modal-body {
      padding: 0;
    }
  }
  
  .button__group {
    display: flex;
    width: 100%;
  
    .button__cancel {
      background-color: #ffffff !important;
      border-color: #fff !important;
      color: $dark !important;
      box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
      border-radius: 0px 0px 0px 16px;
      width: 100%;
      height: 72px;
      font-size: 16px;
      font-weight: 800;
    }
  
    .button__save {
      background-color: #45b6ab !important;
      border-color: #45b6ab !important;
      box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
      border-radius: 0px 0px 16px 0px;
      width: 100%;
      height: 72px;
      font-size: 16px;
      font-weight: 800;
    }
  }
  </style>
  