<template>
  <div class="mr-1" style="position: relative">
    <b-button
      class="bg-white h-100"
      @click="isShow = !isShow"
      id="button--filter"
    >
      <b-img :src="require('@/assets/images/icons/filter.svg')" />
      <span>Filter</span>
    </b-button>
    <Transition name="fade" mode="out-in">
      <div
        v-if="isShow"
        class="bg-white content__filter"
        :style="
          $route.name == 'report.index' ? 'left:unset;transform:unset' : ''
        "
      >
        <div class="d-flex justify-content-between align-items-center py-1">
          <div
            class="px-1 custom__dropdown--item"
            role="button"
            @click="isShow = false"
            id="button--close__filter"
          >
            <feather-icon
              size="16"
              class="text-dark fw-bold-800"
              style="margin-right: 5px"
              icon="ArrowLeftIcon"
            />
            <span class="text-dark fw-bold-700 size14"> Filter Data </span>
          </div>
          <b-button
            class="mr-1"
            @click="submitFilter"
            id="button--submit__filter"
          >
            Terapkan
          </b-button>
        </div>
        <hr />
        <b-form-checkbox
          v-model="checkTanggal"
          class="custom-control-primary m-1"
        >
          Tanggal
        </b-form-checkbox>
        <b-collapse
          id="tanggal"
          class="p-1"
          style="background-color: #f6f7fa"
          :visible="checkTanggal"
        >
          <div class="d-flex flex-column">
            <div class="d-flex justify-content-between">
              <b-input-group class="input-group-merge">
                <flat-pickr
                  v-model="rangeDate"
                  class="form-control"
                  :config="{
                    mode: selected === 'daterange' ? 'range' : 'single',
                  }"
                />
                <b-input-group-append is-text>
                  <b-img
                    :src="require('@/assets/images/icons/calendar.svg')"
                    alt="dateIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </b-collapse>
        <hr v-if="is_transaction" />
        <b-form-checkbox
          v-if="is_transaction"
          v-model="checkStatus"
          class="custom-control-primary m-1"
        >
          Status Berlangganan
        </b-form-checkbox>
        <b-collapse
          id="status"
          class="p-1"
          style="background-color: #f6f7fa"
          :visible="checkStatus"
        >
          <div class="d-flex flex-column">
            <b-form-group>
              <b-form-checkbox
                v-model="filter.renewal_status"
                value="free"
                class="custom-control-primary"
              >
                Gratis
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="filter.renewal_status"
                value="trial"
                class="custom-control-primary"
              >
                Trial
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="filter.renewal_status"
                value="subscribe"
                class="custom-control-primary"
              >
                Berlangganan
              </b-form-checkbox>
            </b-form-group>
          </div>
        </b-collapse>
      </div>
    </Transition>
  </div>
</template>

<script>
import {
  BImg,
  BButton,
  BCollapse,
  VBToggle,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  components: {
    BImg,
    BButton,
    BCollapse,
    BFormCheckbox,
    vSelect,
    BFormGroup,
    BFormInput,
    flatPickr,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    styleName: {
      type: String,
      default: "",
    },
    isOpname: {
      type: Boolean,
    },
    isOrder: {
      type: Boolean,
    },
    isRetur: {
      type: Boolean,
    },
    is_actual: {
      type: Boolean,
      default: false,
    },
    isPayoutOther: {
      type: Boolean,
      default: false,
    },
    is_transaction: {
      type: Boolean,
      default: false,
    },
    is_stock: {
      type: Boolean,
      default: false,
    },
    getData: {
      type: Function,
    },
  },
  data() {
    return {
      selected: "",
      options: [
        {
          label: "date",
          title: "Tanggal",
        },
      ],
      selectedHari: "Hari",
      optionsDay: ["Hari"],
      checkTanggal: false,
      checkStatus: false,
      checkProduct: false,
      pelanggan: {
        is_show: false,
        options: ["Ritel", "Non-Ritel"],
        checkboxValue: "Ritel",
      },
      rangeDate: null,
      isShow: false,
      filter: {
        // product_type: "",
        // filter_date_type: "",
        register_date: "",
        per_page: 10,
      },
    };
  },
  watch: {
    // selected(value) {
    //   this.filter.filter_date_type = value;
    // },
    // checkTanggal(value) {
    //   if (value === false) {
    //     this.filter.filter_date_type = ''
    //     this.filter.register_date = ''
    //   }
    // },
    // checkStatus(value) {
    //   if (value === false) {
    //     this.filter.product_type = ''
    //   }
    // },
  },
  methods: {
    submitFilter() {
      if (this.checkTanggal === true) {
        // this.filter.filter_date_type = this.selected;
        this.filter.register_date =
          this.selected === "daterange"
            ? this.rangeDate.toString().replace(/\s/g, "").replace("to", "~")
            : this.rangeDate;
      }
      if (this.checkTanggal === false) {
        // this.filter.filter_date_type = "";
        this.filter.register_date = "";
      }
      if (this.checkStatus === false) {
        this.filter.renewal_status = "";
      }
      this.filter.renewal_status = !this.filter.renewal_status
        ? null
        : this.filter.renewal_status;
      this.$emit("filterBase", this.filter);
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.content__filter {
  position: absolute;
  z-index: 999;
  width: 311px;
  left: -50%;
  transform: translateX(-50%);
  border-radius: 16px;
  margin-top: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.input-group {
  input {
    background: #f1f2f5;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: none;
  }

  .input-group-append {
    .input-group-text {
      background-color: #f1f2f5;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: none;
    }
  }
}
</style>
